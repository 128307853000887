body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(components/algorand-algo-logo.svg) black;
  background-position: center top;
  background-size: auto 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.semi-transparent {
  background: rgba(255, 255, 255, 0.09) !important;
}

.hover-me:hover {
  transition: 400ms transform;
  transform: scale(1.5);
  cursor: pointer;
}

@keyframes anim-bg-fade {
  0% {
    background-color: #121212;
  }
  100% {
    background-color: black
  }
}

@keyframes anim-fg-fade {
  0% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logo-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

.fg-fade {
  animation: anim-fg-fade 2s forwards;
}

.bg-fade {
  animation: anim-bg-fade 6s forwards;
}

  @keyframes i-fade-in {
    0% {
      transform: scale(5.0);
      mix-blend-mode: color-burn;
      opacity: 0;
    }
    5% {
      opacity: 0.5;
    }
    10% {
      opacity: 0.5;
    }
    15% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    24.9% {
      opacity: 0;
      mix-blend-mode: color-burn;
    }
    25% {
      mix-blend-mode: color-dodge;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    59.9% {
      opacity: 0;
    }
    60% {
      transform: scale(3.5);
      mix-blend-mode: color-dodge;
      opacity: 0;
    }
    61% {
      opacity: 0;
      transform: scale(3);
      mix-blend-mode: screen;
    }
    70% {
      opacity: 0;
    }
    75% {
      opacity: 0.1;
    }
    80% {
      opacity: 0.1;
    }
    92% {
      opacity: 1;
      transform: scale(1.5);
      mix-blend-mode: screen;
    }
    100% {
      mix-blend-mode: screen;
      transform: scale(0.50) translateY(20%);
      opacity: 0.5;
    }
  }

  @keyframes fade-in {
    0% {
      transform: scale(5.0);
      mix-blend-mode: color-burn;
      opacity: 0;
    }
    5% {
      opacity: 0.5;
    }
    10% {
      opacity: 0.5;
    }
    15% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    24.9% {
      opacity: 0;
      mix-blend-mode: color-burn;
    }
    25% {
      mix-blend-mode: color-dodge;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    59.9% {
      opacity: 0;
    }
    60% {
      transform: scale(3.5);
      mix-blend-mode: color-dodge;
      opacity: 0;
    }
    61% {
      opacity: 0;
      transform: scale(3);
      mix-blend-mode: screen;
    }
    70% {
      opacity: 0;
    }
    75% {
      opacity: 0.1;
    }
    80% {
      opacity: 0.1;
    }
    92% {
      opacity: 1;
      transform: scale(1.5);
      mix-blend-mode: screen;
    }
    100% {
      mix-blend-mode: screen;
      transform: scale(0.50) translateY(20%);
      opacity: 0.5;
    }
  }

  @keyframes i-anima {
    0% {
      opacity: 0.5;
      transform: scale(0.50) translateY(20%);
      mix-blend-mode: screen;
    }
    50% {
      transform: scale(0.75) translateY(-30%);
    }
    100% {
      opacity: 1;
      transform: none;
      mix-blend-mode: screen;
    }
  }

  @keyframes anima {
    0% {
      opacity: 0.8;
      transform: scale(0.50) translateY(20%);
      mix-blend-mode: screen;
    }
    50% {
      transform: scale(0.75) translateY(-30%);
    }
    100% {
      opacity: 1;
      transform: none;
      mix-blend-mode: screen;
    }
  }

  @keyframes pulse-scale {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.92);
      opacity: 0.8
    }
  }

  @keyframes ranima {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 1px 1px rgba(118,249,53,0.1);
  }
  50% {
    box-shadow: 0 0 3px 3px rgba(118,249,53,0.25);
  }
  100% {
    box-shadow: 0 0 1px 1px rgba(118,249,53,0.1);
  }
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@keyframes fade-pulse {
  0% {
    opacity: 0.1;
    transform: scale(1.0) translate(-50%) skewX(0);
  }
  22% {
    opacity: 0.05;
    transform: translate(-35%) skewX(5deg);
  }
  28% {
    opacity: 0.05;
    transform: translate(-35%) skewX(5deg);
  }
  50% {
    opacity: 0.1;
  }
  72% {
    opacity: 0.05;
    transform: translate(-65%) skewX(-5deg);
  }
  78% {
    opacity: 0.05;
    transform: translate(-65%) skewX(-5deg);
  }
  100% {
    opacity: 0.1;
    transform: scale(1.0) translate(-50%) skewX(0);
  }
}


.huge {
  color: inherit !important;
  font-size: 20vw !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold !important;
  letter-spacing: -1.8vw;
}

.delayed {
  opacity: 0.4;
}

.delayed.show {
  opacity: 1.0;
}

* {
  border-radius: 0px !important;
}

.SnackbarItem-variantInfo {
  background-color: #121212 !important;
  border: 2px #76f935 solid;
}

.SnackbarItem-variantInfo,
.SnackbarItem-variantInfo .MuiSvgIcon-root,
.SnackbarItem-variantInfo .MuiButton-root {
  color: #76f935 !important;
  color: #76f935 !important;
}


